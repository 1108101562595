import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Accordion from "./Accordion";

const HelpSupport = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const faqs = [
    {
      question: "What is VirtuNotes?",
      answer:
        "VirtuNotes is an advanced AI-powered tool designed to help students by converting lectures and meetings into concise summaries and key points, enabling efficient studying and revision.",
    },
    {
      question: "How does VirtuNotes work?",
      answer:
        "Users record their class sessions, and VirtuNotes processes the audio to generate summaries and key points. It uses advanced AI to identify crucial information, making study and revision more effective.",
    },
    {
      question: "Can I edit the summaries generated by VirtuNotes?",
      answer:
        "Yes, users can edit the summaries and add personal notes, tailoring the generated content to their individual study needs.",
    },
    {
      question: "What languages does VirtuNotes support?",
      answer:
        "VirtuNotes supports multiple languages, catering to a diverse student base worldwide. It's continually updated to include more languages.",
    },
    {
      question: "How accurate are the transcriptions and summaries?",
      answer:
        "VirtuNotes provides highly accurate transcriptions and summaries. However, the clarity of the recording can affect the quality of the results.",
    },
    {
      question: "Is VirtuNotes free to use?",
      answer:
        "VirtuNotes offers both free and premium plans. The free version has basic features, while the premium plans offer advanced functionalities.",
    },
    {
      question: "How secure is my data with VirtuNotes?",
      answer:
        "We prioritize data security and privacy. All data processed through VirtuNotes is encrypted and securely stored, adhering to strict data protection regulations.",
    },
    {
      question: "Can VirtuNotes be used for professional meetings?",
      answer:
        "Absolutely! VirtuNotes is versatile and can be used for various purposes, including professional meetings, to provide concise summaries and key points.",
    },
    {
      question: "How does the quiz generation feature work?",
      answer:
        "For Student Plan users, VirtuNotes offers a quiz generation feature, which creates customized quizzes based on class materials like presentations, documents, and recordings.",
    },
    {
      question: "What customer support options are available?",
      answer:
        "Our dedicated support team can be reached via email at support@virtunotes.com. We also offer live chat support and a comprehensive knowledge base on our website.",
    },
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col lg:ml-[250px] p-10">
        <h1 className="text-4xl font-bold mt-12 mb-6">Help & Support</h1>
        <div className="mb-8">
          <input
            type="text"
            placeholder="Search for help..."
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        {/* FAQ Section */}
        <div className="space-y-6">
          <h2 className="text-2xl font-bold mb-4">
            Frequently Asked Questions
          </h2>
          {filteredFaqs.map((faq, index) => (
            <Accordion key={index} title={faq.question} content={faq.answer} />
          ))}
        </div>

        {/* Contact Support */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Contact Support</h2>
          <p className="mb-4">
            If you need further assistance, please fill out our contact form
            below or email us at support@virtunotes.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpSupport;
